import React from 'react';

import './styles.scss';

function Label({ children, isRequired }) {
  return (
    <span className="text-body-baseline label">
      {children}
      <span className="label_asterisk">{isRequired ? '*' : ''}</span>
    </span>
  );
}

export default Label;
