import * as yup from 'yup';
import { phone } from 'phone';

import { isPhoneNumberValid } from 'utils/phone';

export const formSchema = yup.object().shape({
  business_information: yup.object().shape({
    company_name: yup.string().required('This is a required field'),
  }),
  jobs: yup.array().of(
    yup.object().shape({
      role_title: yup.string().required('This is a required field'),
      district: yup.string().required('This is a required field'),
      gender: yup.string().required('This is a required field'),
      degree: yup.string().required('This is a required field'),
      years_of_experience: yup.string().required('This is a required field'),
    })
  ),
});
