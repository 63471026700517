import axios from 'axios';

const BASE_URL = `${process.env.GATSBY_HOST_PK_BE}/api`;
const pasangLokerService = {
  getSkills: async () => {
    const res = await axios.get(`${BASE_URL}/pk/skill`);
    return res.data;
  },
  getDocuments: async () => {
    const res = await axios.get(`${BASE_URL}/pk/document`);
    return res.data;
  },
  postCompanyLogo: async (file, fileName) => {
    const res = await axios.post(`${BASE_URL}/pr/file-company-logo/${fileName}`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  },
};

export default pasangLokerService;
