import React, { useState } from 'react';

import Input from 'components/input';
import Dropdown from 'components/dropdown';
import Textarea from 'components/textarea';
import Radio from 'components/radio';

import Trash from 'assets/icons/trash.inline.svg';
import AngleDown from 'assets/icons/angle-down.inline.svg';

import './styles.scss';
import DropdownMultiple from 'components/dropdownMultiple';
import moment from 'moment';
import { pasangLokerService } from 'services';
import { numberWithCommas } from 'utils/string';

function Job({ index, content, errors, touched, values, setFieldValue }) {
  const [isCardOpen, setIsCardOpen] = useState(true);
  const [skills, setSkills] = useState([]);
  const [documents, setDocuments] = useState([]);

  function toggleCard() {
    setIsCardOpen(!isCardOpen);
  }

  function keyPressHandler(e) {
    if (e.key === 'Enter' || e.key === ' ') {
      toggleCard();
    }
  }

  function deleteJob(e) {
    e.stopPropagation();
    setFieldValue(
      'jobs',
      values.jobs.filter((_, i) => index !== i)
    );
  }

  function inputHandler(e, field) {
    const jobs = values.jobs;
    jobs[index][field] = e.target.value;
    setFieldValue('jobs', jobs);
  }

  function inputCurrencyHandler(e, field) {
    const jobs = values.jobs;
    jobs[index][field] = numberWithCommas(e.target.value.replace(/[^0-9]/g, ''));
    setFieldValue('jobs', jobs);
  }

  function inptuNumberHandler(e, field) {
    const jobs = values.jobs;
    jobs[index][field] = e.target.value.replace(/[^0-9]/g, '');
    setFieldValue('jobs', jobs);
  }

  function dropdownHandler(e, field) {
    const jobs = values.jobs;
    jobs[index][field] = e.value;
    setFieldValue('jobs', jobs);
  }

  function dropdownMultipleHandler(e, field) {
    const jobs = values.jobs;
    jobs[index][field] = e;
    setFieldValue('jobs', jobs);
  }

  const typeOfWorkOptions = content.type_of_work.options.map((option) => ({
    id: `${option.text}-${index}`,
    name: `typeOfWork-${option.text}`,
    label: option.text,
    value: option.text,
    onChange: () => {
      const jobs = values.jobs;
      jobs[index].type_of_work = option.text;
      setFieldValue('jobs', jobs);
    },
  }));

  const genderOptions = content.gender.options.map((option) => ({
    id: `${option.text}-${index}`,
    name: `gender-${option.text}`,
    label: option.text,
    value: option.text,
    onChange: () => {
      const jobs = values.jobs;
      jobs[index].gender = option.text;
      setFieldValue('jobs', jobs);
    },
  }));

  const maxAgeOptions = content.is_max_age.options.map((option) => ({
    id: `${option.text}-${index}`,
    name: `maxAge-${option.text}`,
    label: option.text,
    value: option.text,
    onChange: () => {
      const jobs = values.jobs;
      jobs[index].is_max_age = option.text;
      setFieldValue('jobs', jobs);
    },
  }));

  const employmentTypeOptions = content.employment_type.options.map((option) => ({
    label: option.text,
    value: option.text,
  }));

  const degreeOptions = content.degree.options.map((option) => ({
    label: option.text,
    value: option.text,
  }));

  const yearsOfExperienceOptions = content.years_of_experience.options.map(
    (option) => ({
      label: option.text,
      value: option.text,
    })
  );

  const mainSkillOptions = skills.map((option) => ({
    label: option.name,
    value: option.name,
  }));

  const documentsOptions = documents.map((option) => ({
    label: option.name,
    value: option.name,
  }));

  const angleClass = `job_angle ${isCardOpen ? 'job_angle___toggled' : ''}`;

  React.useEffect(async () => {
    const get_skills = await pasangLokerService.getSkills();
    const get_documents = await pasangLokerService.getDocuments();
    setSkills(get_skills.data.list);
    setDocuments(get_documents.data.list);
  }, []);

  return (
    <div className="job">
      <div
        className="job_title"
        type="button"
        role="button"
        tabIndex={0}
        onKeyPress={keyPressHandler}
        onClick={toggleCard}
      >
        <p className="text-gilmer-bold job_title_p">Lowongan Kerja {index + 1}</p>
        <div>
          {values.jobs.length > 1 && (
            <button className="job_delete" type="button" onClick={deleteJob}>
              <Trash />
            </button>
          )}
          <AngleDown className={angleClass} />
        </div>
      </div>
      {isCardOpen && (
        <div className="job_form">
          <div>
            <Input
              isRequired
              name="company_name"
              placeholder={`Masukan nama perusahaan`}
              error={errors?.business_information?.business_information?.company_name}
              touched={touched?.company_name}
              label={'Nama perusahaan'}
              value={values.business_information.company_name}
              onChange={(e) =>
                setFieldValue('business_information', {
                  ...values.business_information,
                  company_name: e.target.value,
                })
              }
            />
            <Input
              isRequired
              error={errors?.jobs?.[index]?.role_title}
              touched={touched?.jobs?.[index]?.role_title}
              name="role_title"
              label={content.role_title.label}
              placeholder={`${'Contoh: '}${content.role_title.placeholder}`}
              value={values.jobs[index].role_title}
              onChange={(e) => inputHandler(e, 'role_title')}
            />

            <Textarea
              rows={4}
              error={errors?.jobs?.[index]?.description}
              name="description"
              label={content.description.label}
              placeholder={`${'Contoh: '}${content.description.placeholder}`}
              value={values.jobs[index].description}
              onChange={(e) => inputHandler(e, 'description')}
            />

            <Textarea
              rows={4}
              isRequired
              error={errors?.jobs?.[index]?.district}
              touched={touched?.jobs?.[index]?.district}
              name="district"
              label={content.district.label}
              placeholder={`${'Contoh: '}${content.district.placeholder}`}
              value={values.jobs[index].district}
              onChange={(e) => inputHandler(e, 'district')}
            />

            <Dropdown
              error={errors?.jobs?.[index]?.employment_type}
              name="employment_type"
              label={content.employment_type.label}
              placeholder={content.employment_type.placeholder}
              options={employmentTypeOptions}
              value={
                values.jobs[index].employment_type
                  ? {
                      value: values.jobs[index].employment_type,
                      label: values.jobs[index].employment_type,
                    }
                  : null
              }
              onChange={(e) => dropdownHandler(e, 'employment_type')}
            />

            <Radio
              error={errors?.jobs?.[index]?.type_of_work}
              checked={values.jobs[index].type_of_work}
              name="type_of_work"
              label={content.type_of_work.label}
              options={typeOfWorkOptions}
            />

            <div className="job_salary">
              <Input
                name="min_salary"
                label="Kisaran Gaji (Bulan)"
                value={values.jobs[index].min_salary}
                placeholder="Rp x,xxxx"
                onChange={(e) => inputCurrencyHandler(e, 'min_salary')}
              />
              <Input
                name="max_salary"
                placeholder="Rp xx,xxxx"
                value={values.jobs[index].max_salary}
                onChange={(e) => inputCurrencyHandler(e, 'max_salary')}
              />
            </div>
          </div>
          <div>
            <Radio
              isRequired
              error={errors?.jobs?.[index]?.gender}
              touched={touched?.jobs?.[index]?.gender}
              checked={values.jobs[index].gender}
              name="gender"
              label={content.gender.label}
              options={genderOptions}
            />

            <Radio
              isRequired
              error={errors?.jobs?.[index]?.is_max_age}
              touched={touched?.jobs?.[index]?.is_max_age}
              checked={values.jobs[index].is_max_age}
              name="is_max_age"
              label={content.is_max_age.label}
              options={maxAgeOptions}
            />

            {values.jobs[index].is_max_age === 'Isi tahun' && (
              <Input
                isRequired
                error={errors?.jobs?.[index]?.max_age}
                name="max_age"
                placeholder={`${'Contoh: '}${content.max_age.placeholder}`}
                value={values.jobs[index].max_age}
                onChange={(e) => inptuNumberHandler(e, 'max_age')}
              />
            )}

            <Dropdown
              isRequired
              error={errors?.jobs?.[index]?.degree}
              touched={touched?.jobs?.[index]?.degree}
              name="degree"
              label={content.degree.label}
              placeholder={content.degree.placeholder}
              options={degreeOptions}
              value={
                values.jobs[index].degree
                  ? {
                      value: values.jobs[index].degree,
                      label: values.jobs[index].degree,
                    }
                  : null
              }
              onChange={(e) => dropdownHandler(e, 'degree')}
            />

            <Input
              isRequired
              type="number"
              error={errors?.jobs?.[index]?.years_of_experience}
              touched={touched?.jobs?.[index]?.years_of_experience}
              name="years_of_experience"
              label={content.years_of_experience.label}
              placeholder={content.years_of_experience.placeholder}
              onChange={(e) => inputHandler(e, 'years_of_experience')}
            />

            <DropdownMultiple
              error={errors?.jobs?.[index]?.main_skill}
              name="main_skill"
              label={content.main_skill.label}
              placeholder={content.main_skill.placeholder}
              options={mainSkillOptions}
              value={values.jobs[index].main_skill || null}
              onChange={(e) => dropdownMultipleHandler(e, 'main_skill')}
            />

            <DropdownMultiple
              error={errors?.jobs?.[index]?.documents}
              name="documents"
              label={content.documents.label}
              placeholder={content.documents.placeholder}
              options={documentsOptions}
              value={values.jobs[index].documents || null}
              onChange={(e) => dropdownMultipleHandler(e, 'documents')}
            />

            <Input
              type="date"
              error={errors?.jobs?.[index]?.expired}
              name="expired"
              label={content.expired.label}
              placeholder={`${'Contoh: '}${content.expired.placeholder}`}
              value={values.jobs[index].expired}
              onChange={(e) => inputHandler(e, 'expired')}
              min={moment().add(1, 'day').format('YYYY-MM-DD')}
            />

<Input
              type="number"
              error={errors?.jobs?.[index]?.total_vacancies}
              touched={touched?.jobs?.[index]?.total_vacancies}
              name="total_vacancies"
              label={content.total_vacancies.label}
              placeholder={content.total_vacancies.placeholder}
              onChange={(e) => inputHandler(e, 'total_vacancies')}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Job;
