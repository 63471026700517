import React from 'react';

import Label from '../label';

import './styles.scss';

function Number({
  isDisabled = false,
  label,
  name,
  value,
  placeholder,
  error,
  isRequired = false,
  onChange,
  type = 'text',
  touched,
}) {
  return (
    <label htmlFor={name}>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <div className="numberinput">
        <div className="numberinput_number">
          <p className="text-body-baseline">🇮🇩 +62</p>
        </div>
        <input
          name={name}
          placeholder={placeholder}
          value={value}
          aria-label={label}
          className="text-body-baseline numberinput_input"
          disabled={isDisabled}
          id={name}
          type={type}
          onChange={onChange}
        />
      </div>
      {touched && error && <p className="text-error">{error}</p>}
    </label>
  );
}

export default Number;
