export function removeFirstWord(str) {
  const indexOfSpace = str.indexOf(' ');
  if (indexOfSpace === -1) {
    return '';
  }
  return str.substring(indexOfSpace + 1);
}

export function getFirstWord(str) {
  return str.split(' ')[0];
}

export function numberWithCommas(x) {
  return Number(x).toLocaleString('id-ID');
}

export function removeComma(x) {
  return Number(x.toString().replace(/,/g, ''));
}
