import React from 'react';

import Button from 'components/button';
import buttonVariants from 'components/button/constants/buttonVariants';

import { initialJobsValues } from 'widgets/@employer/form/constants';
import Plus from 'assets/icons/plus.inline.svg';

import Job from '../job';

import './styles.scss';

function JobDetails({ content, errors, touched, values, setFieldValue }) {
  function addJob() {
    setFieldValue('jobs', [...values.jobs, { ...initialJobsValues }]);
  }

  return (
    <div className="jobdetails">
      {values.jobs.map((_, index) => {
        return (
          <Job
            key={`job-${index}`}
            content={content}
            errors={errors}
            touched={touched}
            values={values}
            index={index}
            setFieldValue={setFieldValue}
          />
        );
      })}
      <Button variant={buttonVariants.TERTIARY} onClick={addJob}>
        Tambah lowongan lagi
        <Plus className="button_icon" />
      </Button>
    </div>
  );
}

export default JobDetails;
