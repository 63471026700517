import React from 'react';

import Seo from 'components/seo';
import NewLayout from 'components/newLayout';
import Form from 'widgets/@employer/form';

function Employer() {
  return (
    <NewLayout hideDownload hideKeywords>
      <Seo title="Pintarnya Kerja | Pasang Loker" />
      <Form />
    </NewLayout>
  );
}

export default Employer;
