export const initialJobsValues = {
  role_title: '',
  description: '',
  district: '',
  employment_type: '',
  type_of_work: '',
  min_salary: '',
  max_salary: '',
  gender: '',
  is_max_age: 'Tidak ada',
  max_age: '',
  degree: '',
  years_of_experience: '',
  main_skill: null,
  documents: null,
  expired: '',
};
