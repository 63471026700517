export const dropdown = {
  container: (base) => ({
    ...base,
    margin: '0 0 1rem',
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: 'var(--color-primary-white)',
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: state.isFocused
      ? 'var(--color-primary-blue)'
      : 'var(--color-secondary-cement)',
    padding: '0.75rem',

    '&:hover': {
      borderColor: 'var(--color-primary-blue)',
    },
  }),
  placeholder: (base, state) => ({
    ...base,
    display:
      state.isFocused || state.isSelected || state.selectProps.inputValue
        ? 'none'
        : 'block',
    margin: '0',
    color: 'var(--color-primary-dust)',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'var(--color-primary-dark-blue)',
    fontWeight: 600,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0',
    fontFamily: 'Gilmer, sans-serif',
    fontSize: '0.925rem',
    lineHeight: '1.5',
    overflow: 'initial',
    color: 'var(--color-primary-dark-blue)',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '0',
    svg: {
      height: '16px',
      width: '16px',
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: '0 8px',
    svg: {
      height: '16px',
      width: '16px',
    },
  }),
  multiValue: (base) => ({
    ...base,
    margin: '0 2px 0 0',
  }),
  noOptionsMessage: (base) => ({
    ...base,
    fontFamily: 'Gilmer, sans-serif',
    fontSize: '0.875rem',
    lineHeight: '1.5',
  }),
  groupHeading: (base) => ({
    ...base,
    fontFamily: 'Gilmer, sans-serif',
    fontSize: '0.75rem',
    lineHeight: '1.5',
  }),
  input: (base) => ({
    ...base,
    margin: '0',
    padding: '0',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor:
      state.isFocused || state.isSelected ? 'var(--color-secondary-silk)' : null,
    opacity: '1',
    fontFamily: 'Gilmer, sans-serif',
    fontSize: '0.875rem',
    lineHeight: '1.5',
    overflow: 'initial',
    color: 'var(--color-primary-dark-blue)',
  }),
};
