import React from 'react';

import Select from 'react-select';

import Label from '../label';

import * as style from './styles';

function DropdownMultiple({
  options,
  name,
  value,
  error,
  onChange,
  placeholder,
  label,
  disabled,
  isRequired = false,
}) {
  return (
    <label htmlFor={name}>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <Select
        options={options}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        styles={style.dropdown}
        isDisabled={disabled}
        isMulti
      />
      {error && <p className="text-error">{error}</p>}
    </label>
  );
}

export default DropdownMultiple;
