import React from 'react';

import Label from '../label';

import './styles.scss';

function Radio({ checked, options, label, isRequired, error, touched }) {
  return (
    <div className="radio">
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <div className="radio_inputs">
        {options.map((option, index) => (
          <div className="radio_radio" key={index}>
            <input
              checked={checked === option.value}
              className="radio_input"
              type="radio"
              name={option.name}
              id={option.id}
              value={option.value}
              onChange={option.onChange}
            />
            <label className="text-body-baseline radio_input_label" htmlFor={option.id}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
      {touched && error && (
        <p className="text-error" style={{ marginTop: 12 }}>
          {error}
        </p>
      )}
    </div>
  );
}

export default Radio;
