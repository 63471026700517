import React from 'react';

import Label from '../label';

import './styles.scss';

function Textarea({
  isDisabled,
  label,
  name,
  value,
  placeholder,
  error,
  isRequired,
  onChange,
  rows,
  touched,
}) {
  return (
    <label htmlFor={name}>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <textarea
        name={name}
        placeholder={placeholder}
        value={value}
        aria-label={label}
        className="text-body-baseline textarea"
        disabled={isDisabled}
        id={name}
        onChange={onChange}
        rows={rows}
      />
      {touched && error && <p className="text-error">{error}</p>}
    </label>
  );
}

export default Textarea;
