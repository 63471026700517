import axios from 'axios';
import axiosRetry from 'axios-retry';

const LIST_ID = 1213;
const BASE_URL = '/.netlify/functions/subscriber';

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 200;
  },
  retryCondition: (error) => {
    return error.response.status === 500;
  },
});

const subscriberService = {
  retrieve: ({ emailId }) => {
    return axios.get(BASE_URL, {
      params: {
        emailId,
      },
    });
  },
  update: ({ body, emailId }) => {
    return axios.put(
      BASE_URL,
      {
        ...body,
        list_id: LIST_ID,
      },
      {
        params: {
          emailId,
        },
      }
    );
  },
};

export default subscriberService;
