import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Link from 'components/link';
import Instagram from 'assets/footer/instagram.inline.svg';
import Whatsapp from 'assets/footer/whatsapp.inline.svg';
import Facebook from 'assets/footer/facebook.inline.svg';
import './styles.scss';
import Logo from 'assets/logo.inline.svg';

import useWindowDimensions from 'hooks/useWindowDimensions';

function NewFooter({ hideFooter, hideDownload }) {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const activeEdge = edges.find((edge) => edge?.node?.frontmatter?.newFooter != null);

  const { download, complaints, links, mobilelinks, service, socials } =
    activeEdge.node.frontmatter.newFooter;

  const { width: windowWidth } = useWindowDimensions();

  return (
    !hideFooter && (
      // <div>test</div>
      <footer>
        {!hideDownload && (
          <div className="download grid">
            <div className="download_image start-1 col-6 col-xl-4 col-lg-full">
              <StaticImage
                alt="phone"
                className="download_phone"
                src="../../assets/footer/phone.png"
              />
            </div>
            <div className="download_text col-5 col-xl-3 col-lg-full">
              <h1>{download.headline}</h1>
              <p className="download_subtext">{download.content}</p>
              <div className="download_platforms">
                <Link
                  to={
                    'https://play.google.com/store/apps/details?id=com.pintarnyakerja&referrer=utm_source%3Dwebsite%26utm_medium%3Dwebsite_banner%26utm_term%3Dwebsitebanner%26utm_campaign%3Dwebsitebanner_homepage'
                  }
                >
                  <StaticImage
                    alt="play store"
                    className="download_platforms_image"
                    src="../../assets/footer/play-store.svg"
                    placeholder="blurred"
                  />
                </Link>
              </div>
            </div>
          </div>
        )}

        <div name="contacts" className="footernew grid">
          <div className="start-1 col-4 col-xl-3 col-lg-full">
            <Logo className="nav_logo" />
            <div className="footernew_complaints">
              <p className="footernew_headline text-poppins">{complaints.headline}</p>
              <p className="footernew_p text-poppins">{complaints.content}</p>
            </div>
          </div>
          <div className="footernew_links col-4 col-xl-3 col-lg-full">
            {windowWidth < 992
              ? mobilelinks.map((link) => (
                  <Link
                    key={link.text}
                    className="footernew_link text-link text-poppins"
                    to={link.link}
                  >
                    {link.text}
                  </Link>
                ))
              : links.map((link) => (
                  <Link
                    key={link.text}
                    className="footernew_link text-link text-poppins"
                    to={link.link}
                  >
                    {link.text}
                  </Link>
                ))}
          </div>
          <div className="footernew_service col-4 col-xl-3 col-lg-full">
            <p className="footernew_headline text-poppins">{service.headline}</p>
            <p className="footernew_subtitle text-poppins">
              {service.subtitleEmployee}
            </p>
            <p className="footernew_content text-poppins">{service.contentEmployee}</p>
            <p className="footernew_subtitle text-poppins">
              {service.subtitleEmployer}
            </p>
            <p className="footernew_content text-poppins">{service.contentEmployer}</p>
          </div>
          <div className="footernew_footer start-1 col-full">
            <p className="footernew_footer_p text-poppins">
              ©2022 All Rights Reserved. Pintarnya.
            </p>
            <div className="footernew_footer_links">
              <Link to={socials.instagram}>
                <Instagram />
              </Link>
              <Link to={socials.whatsapp}>
                <Whatsapp />
              </Link>
              <Link to={socials.facebook}>
                <Facebook />
              </Link>
            </div>
          </div>
        </div>
      </footer>
    )
  );
}

export default NewFooter;

const query = graphql`
  query NewFooterQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            newFooter {
              complaints {
                content
                headline
              }
              download {
                headline
                content
              }
              email {
                button
                label
                placeholder
              }
              links {
                link
                text
              }
              mobilelinks {
                link
                text
              }
              service {
                subtitleEmployee
                subtitleEmployer
                contentEmployee
                contentEmployer
                headline
              }
              socials {
                instagram
                whatsapp
                facebook
              }
            }
          }
        }
      }
    }
  }
`;
