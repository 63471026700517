import React, { useState } from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { track } from '@amplitude/analytics-browser';

import GatsbyLink from 'components/link';
import { Link } from 'react-scroll';

import Button from 'components/button';
import Snackbar from 'components/@home/snackbar';
import SnackbarBottom from 'components/@home/snackbarBottom';
import Logo from 'assets/logo-emp.inline.svg';
import List from 'assets/icons/list.inline.svg';
import Close from 'assets/icons/close.inline.svg';
import Instagram from 'assets/footer/instagram.inline.svg';
import Whatsapp from 'assets/footer/whatsapp.inline.svg';
import Facebook from 'assets/footer/facebook.inline.svg';

import './styles.scss';

function NewNavbar({ scrollPosition, isShowSnackbar = false }) {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const activeEdge = edges.find((edge) => edge?.node?.frontmatter?.newNavbar != null);

  const { cta, links, mobilelinks, socials, snackbar, login } =
    activeEdge.node.frontmatter.newNavbar;

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  function toggleMenu() {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }

  const handleClick = (text = '') => {
    switch(text) {
      case 'solutions':
        track('lp_header_solusi_click');
        break;
      case 'partners':
        track('lp_header_partners_click');
        break;
      case 'testimonials':
        track('lp_header_testimonials_click');
        break;
      case 'faq':
        track('lp_header_faq_click');
        break;
      case 'contacts':
        track('lp_header_kontakkami_click');
        break 
      case 'Pasang Loker':
        track('lp_header_pasangloker_click');
        break;
      case 'Login':
        track('lp_header_login_click');
        break;
    }
  }

  return (
    <>
      {scrollPosition > 1000 && isShowSnackbar ? (
        <Snackbar
          headline={snackbar.top.headline}
          jobseekerText={snackbar.top.jobseeker.text}
          jobseekerLink={snackbar.top.jobseeker.link}
          employerText={snackbar.top.employer.text}
          employerLink={snackbar.top.employer.link}
        />
      ) : null}
      <nav className="grid navnew">
        <div className="col-full start-1 navnew_col">
          <GatsbyLink to="/">
            <Logo className="navnew_logo" />
          </GatsbyLink>
          <div className="navnew_links">
            {links?.map((link) => (
              <Link
                key={link.text}
                activeClass="navnew_link___active text-poppins"
                className="text-link text-poppins"
                to={link.link}
                duration={500}
                offset={-200}
                spy={true}
                onClick={() => handleClick(link.link)}
              >
                {link.text}
              </Link>
            ))}
            <div className="navnew_ctas">
              <GatsbyLink 
                onClick={() => handleClick(cta.text)}
                className="navnew_cta" 
                to={cta.link}
                >
                <Button className="text-poppins">{cta.text}</Button>
              </GatsbyLink>
              <GatsbyLink 
                onClick={() => handleClick(login.text)}
                className="navnew_cta" 
                to={login.link}
                >
                <Button variant="secondary" className="text-poppins">
                  {login.text}
                </Button>
              </GatsbyLink>
            </div>
            <button className="navnew_menu" onClick={toggleMenu}>
              <List />
            </button>
          </div>
        </div>
        {isMobileMenuOpen && (
          <div className="navnew_mobile">
            <button className="navnew_mobile_close" onClick={toggleMenu}>
              <Close />
            </button>
            <div className="navnew_mobile_links">
              {mobilelinks?.map((link) => (
                <Link
                  key={link.text}
                  activeClass="navnew_link___active text-poppins"
                  className="text-link text-poppins"
                  to={link.link}
                  spy={true}
                >
                  {link.text}
                </Link>
              ))}
            </div>
            <div className="navnew_mobile_footer">
              <GatsbyLink className="navnew_social" to={socials.instagram}>
                <Instagram />
              </GatsbyLink>
              <GatsbyLink className="navnew_social" to={socials.whatsapp}>
                <Whatsapp />
              </GatsbyLink>
              <GatsbyLink className="navnew_social" to={socials.facebook}>
                <Facebook />
              </GatsbyLink>
            </div>
          </div>
        )}
      </nav>
      {scrollPosition > 1000 && isShowSnackbar ? (
        <SnackbarBottom
          headline={snackbar.bottom.headline}
          buttonText={snackbar.bottom.button.text}
          buttonLink={snackbar.bottom.button.link}
        />
      ) : null}
    </>
  );
}

export default NewNavbar;

const query = graphql`
  query NewNavbarQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            newNavbar {
              socials {
                instagram
                whatsapp
                facebook
              }
              links {
                text
                link
              }
              mobilelinks {
                text
                link
              }
              cta {
                link
                text
              }
              login {
                link
                text
              }
              snackbar {
                top {
                  headline
                  jobseeker {
                    text
                    link
                  }
                  employer {
                    text
                    link
                  }
                }
                bottom {
                  headline
                  button {
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
