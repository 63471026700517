import React from 'react';
import { init } from '@amplitude/analytics-browser';

import NewNavbar from 'components/newNavbar';
import NewFooter from 'components/newFooter';
import 'styles/root.scss';
import './styles.scss';

function NewLayout({ children, hideHeader, hideFooter, hideKeywords, isShowSnackbar=false, hideDownload }) {
  const API_KEY = process.env.GATSBY_AMPLITUDE_API_KEY || '';
  const [scrollPosition, setScrollPosition] = React.useState(0);
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(() => {
    init(API_KEY)
  }, []);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  return (
    <main className={!hideHeader ? 'main' : ''} >
      <header className={hideHeader ? 'd-none' : ''}>
        <NewNavbar scrollPosition={scrollPosition} isShowSnackbar={isShowSnackbar}/>
      </header>
      {children}
      <NewFooter hideKeywords={hideKeywords} hideFooter={hideFooter} hideDownload={hideDownload} />
    </main>
  );
}

export default NewLayout;