import React from 'react';

import Input from 'components/input';
import NumberInput from 'components/number';
import Dropdown from 'components/dropdown';
import Textarea from 'components/textarea';

import './styles.scss';
import Radio from 'components/radio';
import InputFile from 'components/inputFile';

function BusinessInformation({
  content,
  errors,
  touched,
  values,
  setFieldValue,
  resetFileName,
  setFile
}) {
  const industriesHandler = content.industry.options.map((option) => ({
    label: option.text,
    value: option.text,
  }));

  const communicationMethodHandler = content.method_communication.options.map(
    (option) => ({
      id: option.text,
      name: `method_communication-${option.text}`,
      label: option.text,
      value: option.text,
      onChange: () => {
        const businessInformation = values.business_information;
        businessInformation.method_communication = option.text;
        setFieldValue('business_information', businessInformation);
      },
    })
  );

  return (
    <div className="businessinformation">
      <div>
        <Input
          isRequired
          name="company_name"
          placeholder={`${'Contoh: '}${content.company_name.placeholder}`}
          error={errors?.business_information?.company_name}
          touched={touched?.company_name}
          label={content.company_name.label}
          value={values.business_information.company_name}
          setFile={setFile}
          onChange={(e) =>
            setFieldValue('business_information', {
              ...values.business_information,
              company_name: e.target.value,
            })
          }
        />
        <Dropdown
          isRequired
          name="industry"
          error={errors?.business_information?.industry}
          touched={touched?.industry}
          label={content.industry.label}
          options={industriesHandler}
          placeholder={content.industry.placeholder}
          value={
            values.business_information.industry
              ? {
                  value: values.business_information.industry,
                  label: values.business_information.industry,
                }
              : null
          }
          onChange={(e) =>
            setFieldValue('business_information', {
              ...values.business_information,
              industry: e.value,
            })
          }
        />
        <Textarea
          rows={4}
          name="company_description"
          placeholder={`${'Contoh: '}${content.company_description.placeholder}`}
          error={errors?.business_information?.company_description}
          label={content.company_description.label}
          value={values.business_information.company_description}
          onChange={(e) =>
            setFieldValue('business_information', {
              ...values.business_information,
              company_description: e.target.value,
            })
          }
        />
        <Textarea
          rows={4}
          isRequired
          name="company_address"
          placeholder={`${'Contoh: '}${content.company_address.placeholder}`}
          error={errors?.business_information?.company_address}
          touched={touched?.company_address}
          label={content.company_address.label}
          value={values.business_information.company_address}
          onChange={(e) =>
            setFieldValue('business_information', {
              ...values.business_information,
              company_address: e.target.value,
            })
          }
        />
        <InputFile
          name="company_logo"
          placeholder={`${'Contoh: '}${content.company_logo.placeholder}`}
          error={errors?.business_information?.company_logo}
          label={content.company_logo.label}
          value={values.business_information.company_logo}
          style={{ marginBottom: 2 }}
          setFile={setFile}
          onChange={(base64) =>
            setFieldValue('business_information', {
              ...values.business_information,
              company_logo: base64,
            })
          }
          resetFileName={resetFileName}
        />
        <p className="uploadInfo">(Maks: 2 MB, JPG, JPEG, atau PNG)</p>
      </div>
      <div>
        <Input
          isRequired
          name="employer_pic"
          placeholder={`${'Contoh: '}${content.employer_pic.placeholder}`}
          error={errors?.business_information?.employer_pic}
          touched={touched?.employer_pic}
          label={content.employer_pic.label}
          value={values.business_information.employer_pic}
          onChange={(e) =>
            setFieldValue('business_information', {
              ...values.business_information,
              employer_pic: e.target.value,
            })
          }
        />
        <Input
          isRequired
          name="contact_email"
          error={errors?.business_information?.contact_email}
          touched={touched?.contact_email}
          label={content.contact_email.label}
          placeholder={content.contact_email.placeholder}
          value={values.business_information.contact_email}
          onChange={(e) =>
            setFieldValue('business_information', {
              ...values.business_information,
              contact_email: e.target.value,
            })
          }
        />
        <NumberInput
          isRequired
          name="contact_phone"
          error={errors?.business_information?.contact_phone}
          label={content.contact_phone.label}
          touched={touched?.contact_phone}
          placeholder={content.contact_phone.placeholder}
          value={values.business_information.contact_phone}
          onChange={(e) =>
            setFieldValue('business_information', {
              ...values.business_information,
              contact_phone: e.target.value.replace(/[^0-9]/g, ''),
            })
          }
        />

        <Radio
          isRequired
          error={errors?.business_information?.method_communication}
          checked={values.business_information.method_communication}
          name="method_communication"
          label={content.method_communication.label}
          options={communicationMethodHandler}
          touched={touched?.method_communication}
        />

        <Input
          name="referral_code"
          error={errors?.business_information?.referral_code}
          label={content.referral_code.label}
          placeholder={`${'Contoh: '}${content.referral_code.placeholder}`}
          value={values.business_information.referral_code}
          onChange={(e) =>
            setFieldValue('business_information', {
              ...values.business_information,
              referral_code: e.target.value,
            })
          }
        />
      </div>
    </div>
  );
}

export default BusinessInformation;
