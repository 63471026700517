import React from 'react';

import Label from '../label';

import './styles.scss';

function Input({
  isDisabled = false,
  label,
  name,
  value,
  placeholder,
  error,
  touched,
  isRequired = false,
  onChange,
  onKeyDown,
  type = 'text',
  style,
  min,
}) {
  return (
    <label htmlFor={name}>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <input
        name={name}
        placeholder={placeholder}
        value={value}
        aria-label={label}
        className="text-body-baseline input"
        disabled={isDisabled}
        id={name}
        type={type}
        onChange={onChange}
        onKeyDown={onKeyDown}
        style={style}
        min={min}
      />
      {touched && error && <p className="text-error">{error}</p>}
    </label>
  );
}

export default Input;
