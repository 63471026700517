import React, { useState } from 'react';
import { Formik } from 'formik';

import { useStaticQuery, graphql, navigate } from 'gatsby';

import Button from 'components/button';
import buttonVariants from 'components/button/constants/buttonVariants';

import BusinessInformation from 'components/@employer/businessInformation';
import JobDetails from 'components/@employer/jobDetails';

import Whatsapp from 'assets/employer/form/whatsapp.inline.svg';
import ArrowRight from 'assets/icons/arrow-right.inline.svg';

import './styles.scss';

import { initialJobsValues } from './constants';
import { formSchema } from './utils';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { pasangLokerService } from 'services';
import { arrayOfObjectToString } from 'utils/array';

const Form = () => {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const activeEdge = edges.find((edge) => edge?.node?.frontmatter?.post != null);

  const { headline, subtext, number, businessInformation, jobDetails, stats } =
    activeEdge.node.frontmatter.post;

  const [currentStep, setCurrentStep] = useState(1);

  const firstCheckClass = 'form_check';
  const secondCheckClass = currentStep === 1 ? 'form_check' : 'form_check___disabled';
  const formTitle = currentStep === 0 ? businessInformation.title : jobDetails.title;
  const subtextArray = subtext.split('<number>');

  const [isLoading, setIsLoading] = React.useState(false);
  const [resetFileName, setResetFileName] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const closeToast = ({ closeToast }) => (
    <strong
      onClick={closeToast}
      style={{ display: 'flex', alignItems: 'center', marginLeft: 50 }}
    >
      TUTUP
    </strong>
  );
  const formImageUpload = async (companyName) => {
    const formData = new FormData();
    formData.append('company_logo', file);

    const post_company_logo = await pasangLokerService.postCompanyLogo(
      formData,
      companyName
    );
    return post_company_logo;
  };
  return (
    <section className="form grid" id={'pasangloker'}>
      <div className="col-full">
        <h1>{headline}</h1>
        <p>
          {subtextArray[0]}{' '}
          <a className="text-link" href={number.link} target="_blank">
            <Whatsapp className="form_whatsapp" />
            {number.text}
          </a>{' '}
          {subtextArray[1]}
        </p>
        <Formik
          validationSchema={formSchema}
          initialValues={{
            business_information: {
              company_name: '',
              industry: '',
              company_description: '',
              company_address: '',
              company_logo: '',
              employer_pic: '',
              contact_email: '',
              contact_phone: '',
              method_communication: '',
              referral_code: '',
            },
            jobs: [
              {
                ...initialJobsValues,
              },
            ],
          }}
          onSubmit={async (fieldValues, { resetForm }) => {
            let uploadResponse;

            setIsLoading(true);
            setResetFileName(true);
            setFile(null);

            fieldValues.jobs.map((job) => {
              if (
                job.main_skill &&
                typeof job.main_skill != 'string' &&
                job.main_skill.length > 0
              ) {
                job.main_skill = arrayOfObjectToString(job.main_skill);
              }

              if (
                job.documents &&
                typeof job.documents != 'string' &&
                job.documents.length > 0
              ) {
                job.documents = arrayOfObjectToString(job.documents);
              }

              return job;
            });

            const sheetUrl =
              'https://sheet.best/api/sheets/32dd2081-830c-4fad-8f5b-57dcb3b63038';

            try {
              toast.success(
                'Selamat! Lowonganmu sudah terpasang. Apabila diperlukan tim kami akan menghubungi Anda.'
              );
              for (let jobs of fieldValues.jobs) {
                jobs.title = jobs.role_title;
                jobs.min_salary = jobs.min_salary.replace(/\./g, '');
                jobs.max_salary = jobs.max_salary.replace(/\./g, '');

                jobs.employment_type =
                  jobs.employment_type === 'Kontrak'
                    ? 'Contract'
                    : jobs.employment_type;

                jobs.employment_type =
                  jobs.employment_type === 'Magang' ? 'Intern' : jobs.employment_type;

                jobs.employment_type =
                  jobs.employment_type === 'Harian' ? 'Daily' : jobs.employment_type;

                jobs.type_of_work =
                  jobs.type_of_work === 'Di rumah' ? 'Remote' : jobs.type_of_work;

                jobs.gender = jobs.gender === 'Bebas' ? 'Pria/Wanita' : jobs.gender;

                const res = await axios.post(sheetUrl, {
                  ...fieldValues.business_information,
                  company_logo: uploadResponse?.data?.name || '',
                  image_id: uploadResponse?.data?.id || '',
                  created_at: new Date().toLocaleString(),
                  ...jobs,
                });

                if (res.status === 200) {
                  navigate('/pasang-loker');
                  resetForm({
                    values: {
                      business_information: {
                        company_name: '',
                        industry: '',
                        company_description: '',
                        company_address: '',
                        company_logo: '',
                        employer_pic: '',
                        contact_email: '',
                        contact_phone: '',
                        method_communication: '',
                        referral_code: '',
                      },
                      jobs: [
                        {
                          role_title: '',
                          description: '',
                          district: '',
                          employment_type: '',
                          type_of_work: '',
                          min_salary: '',
                          max_salary: '',
                          gender: '',
                          is_max_age: 'Tidak ada',
                          max_age: '',
                          degree: '',
                          years_of_experience: '',
                          main_skill: null,
                          documents: null,
                          expired: '',
                        },
                      ],
                    },
                  });
                  setCurrentStep(0);
                  setIsLoading(false);
                  setResetFileName(false);
                } else {
                  setIsLoading(false);
                  toast.error('Gagal! Lowonganmu belum terpasang');
                }
              }
            } catch (err) {
              setIsLoading(false);
              toast.error('Gagal! Lowonganmu belum terpasang');
            }
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setErrors,
            setFieldValue,
            validateForm,
            touched,
            setTouched,
            /* and other goodies */
          }) => (
            <form onSubmit={(val) => handleSubmit(val)}>
              <div className="form_card">
                <h3 className="form_card_title">{formTitle}</h3>

                <div className={currentStep === 0 ? 'd-block' : 'd-none'}>
                  <BusinessInformation
                    content={businessInformation}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    resetFileName={resetFileName}
                    setFile={setFile}
                  />
                </div>
                <div className={currentStep === 1 ? 'd-block' : 'd-none'}>
                  <JobDetails
                    content={jobDetails}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>

              <div>
                <p style={{ marginLeft: 24 }}>
                  Kolom dengan tanda <span className="label_asterisk">*</span> wajib
                  diisi
                </p>
              </div>

              <div className="form_buttons">
                <div>
                  {/* {currentStep === 1 && (
                    <Button
                      variant={buttonVariants.SECONDARY}
                      onClick={() => {
                        setCurrentStep(0);
                      }}
                    >
                      Kembali
                    </Button>
                  )} */}
                </div>
                <div>
                  {currentStep === 0 && (
                    <Button
                      isDisabled={isLoading}
                      variant={isLoading ? 'disable' : 'primary'}
                      onClick={() => {
                        validateForm().then((e) => {
                          setTouched({
                            company_name: true,
                            company_address: true,
                            contact_email: true,
                            contact_phone: true,
                            industry: true,
                            method_communication: true,
                            employer_pic: true,
                          });
                          if (!e?.business_information) {
                            setErrors({});
                            setCurrentStep(1);
                          }
                        });
                      }}
                    >
                      {isLoading ? 'Lanjut...' : 'Lanjut'}
                      <ArrowRight className="button_icon" />
                    </Button>
                  )}
                  {currentStep === 1 && (
                    <Button
                      type="submit"
                      isDisabled={isLoading}
                      variant={isLoading ? 'disable' : 'primary'}
                    >
                      {isLoading ? 'Pasang...' : 'Pasang'}
                    </Button>
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme="colored"
          style={{ width: 'fit-content' }}
          closeButton={closeToast}
        />

        {/* <div className="form_stats">
          <div className="form_stat">
            <h1 className="form_stat_number">{stats.profiles.number}</h1>
            <p className="form_stat_label">{stats.profiles.label}</p>
          </div>
          <div className="form_stat">
            <h1 className="form_stat_number">{stats.employers.number}</h1>
            <p className="form_stat_label">{stats.employers.label}</p>
          </div>
          <div className="form_stat">
            <h1 className="form_stat_number">{stats.posts.number}</h1>
            <p className="form_stat_label">{stats.posts.label}</p>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Form;

const query = graphql`
  query JobPostQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            post {
              businessInformation {
                title
                company_name {
                  label
                  placeholder
                }
                industry {
                  label
                  placeholder
                  options {
                    text
                  }
                }
                company_description {
                  label
                  placeholder
                }
                company_address {
                  label
                  placeholder
                }
                company_logo {
                  label
                  placeholder
                }
                employer_pic {
                  label
                  placeholder
                }
                contact_email {
                  label
                  placeholder
                }
                contact_phone {
                  label
                  placeholder
                }
                method_communication {
                  label
                  options {
                    text
                  }
                }
                referral_code {
                  label
                  placeholder
                }
              }
              headline
              jobDetails {
                title
                role_title {
                  label
                  placeholder
                }
                description {
                  label
                  placeholder
                }
                district {
                  label
                  placeholder
                }
                employment_type {
                  label
                  placeholder
                  options {
                    text
                  }
                }
                type_of_work {
                  label
                  placeholder
                  options {
                    text
                  }
                }
                gender {
                  label
                  options {
                    text
                  }
                }
                is_max_age {
                  label
                  options {
                    text
                  }
                }
                max_age {
                  placeholder
                }
                degree {
                  label
                  placeholder
                  options {
                    text
                  }
                }
                years_of_experience {
                  label
                  placeholder
                  options {
                    text
                  }
                }
                main_skill {
                  label
                  placeholder
                  options {
                    text
                  }
                }
                documents {
                  label
                  placeholder
                  options {
                    text
                  }
                }
                expired {
                  label
                  placeholder
                }
                total_vacancies {
                  label
                  placeholder
                }
              }
              number {
                text
                link
              }
              stats {
                employers {
                  label
                  number
                }
                posts {
                  label
                  number
                }
                profiles {
                  label
                  number
                }
              }
              subtext
            }
          }
        }
      }
    }
  }
`;
