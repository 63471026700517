import React from 'react';

import Label from '../label';
import Upload from 'assets/icons/upload.inline.svg';

import './styles.scss';

function InputFile({
  isDisabled = false,
  label,
  name,
  value,
  placeholder,
  error,
  isRequired = false,
  onChange,
  type = 'file',
  style,
  resetFileName,
  setFile
}) {
  const fileRef = React.useRef();
  const [fileName, setFileName] = React.useState('');
  const [sizeWarning, setSizeWarning] = React.useState('');

  async function formDataSelect(e) {
    const file = e.target.files[0];

    if (file.size > 2000000) {
      setSizeWarning('Maksimal 2 MB');
      setFileName('');
    } else {
      setSizeWarning('');
      setFile(file)
      setFileName(file.name);

      // const formData = new FormData();
      // formData.append('company_logo', file);

      // const post_company_logo = await pasangLokerService.postCompanyLogo(
      //   formData,
      //   file.name.substr(0, file.name.lastIndexOf('.'))
      // );
      // onChange(post_company_logo?.data?.name || '');
    }
  }

  if (resetFileName && fileName) {
    setFileName('');
  }

  return (
    <label htmlFor={name}>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <div className="p-relative">
        <input
          name="file"
          className="d-none"
          type={type}
          onChange={(e) => formDataSelect(e)}
          ref={fileRef}
          accept="image/jpg, image/jpeg, image/png"
        />

        <input
          name={name}
          placeholder={placeholder}
          value={fileName}
          aria-label={label}
          className="text-body-baseline input"
          disabled={isDisabled}
          id={name}
          type={'text'}
          onClick={() => fileRef.current.click()}
          style={style}
          readOnly
        />
        <div className="upload">
          <Upload />
        </div>
      </div>
      {error || (sizeWarning && <p className="text-error">{error || sizeWarning}</p>)}
    </label>
  );
}

export default InputFile;
